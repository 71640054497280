import React from 'react'

import SEO from "../components/seo"
import Header from '../containers/Header';
import AppSlider from '../containers/AppSlider';
import FaqSection from '../containers/FaqSection';
import WorkSection from '../containers/WorkSection';
import CallToAction from '../containers/CallToAction'
import Testimonial from '../containers/Testimonial';
import PartnerSection from '../containers/PartnerSection';

export default function Product(pageContext) {
  return (
    <>
      <SEO metaData={pageContext.pageContext.seo} lang=""/>
      <Header {...pageContext} />
      <WorkSection items={pageContext.pageContext.services}/>
      <AppSlider screenshots={pageContext.pageContext.screenshots}/>
      <Testimonial />
      <PartnerSection />
      <FaqSection />
      <CallToAction />
    </>
  )
}
