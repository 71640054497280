import React from 'react';
import 'rc-tabs/assets/index.css';
import PropTypes from 'prop-types';
import Tabs, { TabPane } from 'rc-tabs';

import Image from '../../common/components/Image';
import Heading from '../../common/components/Heading';
import Container from '../../common/components/UI/Container';

import { TitleSection } from '../index.style';
import SectionWrapper from './appSlider.style';

const UpdateScreen = ({ 
  secTitleWrapper, 
  secHeading ,
  screenshots
}) => {

  return (
    <SectionWrapper id="screenshot_section">
      <Container>
        <TitleSection>
          <Heading as="h5" content="PLATAFORMA" />
          <Heading content="Conoce más en estas capturas de pantalla." />
        </TitleSection> 
        <Tabs
          className="update-screen-tab"
        >
          {screenshots.map((item, index) => (
            <TabPane
              tab={
                <>
                  {/*<Icon icon={item.icon} size={24} />*/}
                  {item.title}
                </>
              }
              key={index + 1}
            >
              <Image src={item.image.publicURL} alt={`screenshot-${index + 1}`} style={{width: "100%"}} />
            </TabPane>
          ))}
        </Tabs>
      </Container>
    </SectionWrapper>
  );
};

UpdateScreen.propTypes = {
  secTitleWrapper: PropTypes.object,
  secText: PropTypes.object,
  secHeading: PropTypes.object,
};

UpdateScreen.defaultProps = {
  secTitleWrapper: {
    mb: ['60px', '80px'],
  },
  secText: {
    as: 'span',
    display: 'block',
    textAlign: 'center',
    fontSize: '14px',
    letterSpacing: '0.15em',
    fontWeight: '700',
    color: '#ff4362',
    mb: '12px',
  },
  secHeading: {
    textAlign: 'center',
    fontSize: ['20px', '24px', '36px', '36px'],
    fontWeight: '700',
    color: 'headingColor',
    letterSpacing: '-0.025em',
    mb: '0',
    ml: 'auto',
    mr: 'auto',
    lineHeight: '1.12',
    width: '540px',
    maxWidth: '100%',
  },
};

export default UpdateScreen;
