import React from 'react';
import PropTypes from 'prop-types';
import Fade from 'react-reveal/Fade';
import Zoom from 'react-reveal/Zoom';
import parse from 'html-react-parser';
import { openPopupWidget } from "react-calendly"

import Box from '../../common/components/Box';
import Text from '../../common/components/Text';
import Heading from '../../common/components/Heading';
import Button from '../../common/components/Button';
import Card from '../../common/components/Card';
import Image from '../../common/components/Image';
import FeatureBlock from '../../common/components/FeatureBlock';
import Container from '../../common/components/UI/Container';
import VisitorSectionWrapper, { SectionObject } from './header.style';

import ImageBg from '../../common/assets/image/sectionHeaderbackground.png';

const Header = ({ title,
  description,
  textArea,
  imageWrapper,
  btnStyle, 
  featureTitle,
  pageContext 
}) => {

  const calendlyData = {
    url: "https://calendly.com/portaregroup/experto",
    prefill: {},
    pageSettings: {},
    utm: {}
  }

  const handleCalendly = () => {
    openPopupWidget(calendlyData)
  };
 
  return (
    <VisitorSectionWrapper id="visitorSection">
      <SectionObject>
        <Card className="objectWrapper sectionBreakPoint" {...imageWrapper}>
          <Zoom>
            <Image src={ImageBg} alt="BgImage" />
          </Zoom>
          <Card className="dashboardWrapper" {...imageWrapper}>
            <Fade left>
              <Image src={pageContext.headerImage.publicURL} alt="VisitorDashboard1" />
            </Fade>
          </Card>
        </Card>
      </SectionObject>
      <Container className="col">

        <Box {...textArea}>
          <FeatureBlock
            title={
              <Heading
                content={pageContext.titleDetail}
                {...title}
              />
            }
            description={
              <Text
                content={parse(pageContext.descriptionDetail)}
                {...description}
              />
            }
          />
        </Box>

        <Box {...textArea}>

          {pageContext.items.map((feature, index) => (
            <FeatureBlock
              key={`feature_point-${index}`}
              icon={<i className={feature.icon} />}
              iconPosition="left"
              title={<Heading content={parse(feature.title)} {...featureTitle} />}
            />
          ))}

          <Button
            {...btnStyle} 
            title={pageContext.buttonDetailText} 
            onClick={handleCalendly}
          />

        </Box>

      </Container>
    </VisitorSectionWrapper>
  );
};


Header.propTypes = {
  title: PropTypes.object,
  description: PropTypes.object,
  btnStyle: PropTypes.object,
  featureTitle: PropTypes.object,
};

Header.defaultProps = {
  textArea: {
    width: ['100%', '100%', '45%'],
    ml: [0, 0, '58%'],
  },
  imageWrapper: {
    boxShadow: 'none',
  },
  title: {
    fontSize: ['26px', '26px', '30px', '40px'],
    lineHeight: '1.1',
    fontWeight: 'bolder',
    color: 'headingColor',
    letterSpacing: '-0.025em',
    mb: '40px',
  },
  description: {
    fontSize: '16px',
    color: 'textColor',
    lineHeight: '1.75',
    mt: '15px',
    mb: '15px',
  },
  btnStyle: {
    minWidth: ['120px', '120px', '120px', '156px'],
    fontSize: ['15px', '16px'],
    colors: 'main',
    backgroundColor: 'main',
    mt: '25px'
  },
  featureTitle: {
    fontSize: '16px',
    fontWeight: '400',
    color: 'textColor',
    lineHeight: '1.5',
    mb: '8px',
    letterSpacing: '-0.020em',
  },
};

export default Header;
